<template>
	<div>
		<v-row>
			<v-col cols="12" lg="12" md="8">
				<s-crud
					title="Configuración Vehículos"
					add
					edit
					remove
					:config="this.config"
					@save="save($event)"
					:filter="filter"
				>
					<template scope="props">
						<v-container class="">
							<v-row justify="center">
								<v-col cols="3" class="s-col-form">
									<s-select-generic
										label="Placa"
										autocomplete
										itemvalue="VehPlate"
										id="VehID"
										itemtext="VehPlate"
										:config="configVehicle"
										full
										v-model="props.item.VehPlate"
									>
									</s-select-generic>
								</v-col>
								<v-col cols="3" class="s-col-form">
									<s-text
										label="Capacidad Min"
										number
										autofocus
										v-model.number="props.item.CfvCapacityMin"
									>
									</s-text>
								</v-col>
								<v-col cols="3" class="s-col-form">
									<s-text
										label="Capacidad Max"
										number
										autofocus
										v-model.number="props.item.CfvCapacity"
									>
									</s-text>
								</v-col>
								<v-col cols="3" class="s-col-form">
									<s-select-definition
										:disabled="props.item.VehID > 0"
										label="Tipo Carga"
										:def="1231"
										v-model="props.item.ArcID"
									/>
								</v-col>
							</v-row>
						</v-container>
					</template>
				</s-crud>
			</v-col>
		</v-row>
	</div>
</template>
<script>
	import _sVehicleService from "../../../services/Technicalassistance/ConfigurationVehicle";

	export default {
		components: {},
		data: () => ({
			config: {
				model: {
					CfvID: "ID",
				},
				service: _sVehicleService,
				headers: [
					{
						text: "ID",
						value: "CfvID",
						sortable: true,
						align: "end",
						width: "10%",
					},
					{ text: "Placa", value: "VehPlate" },
					{ text: "Serie", value: "VehSerie" },
					{ text: "Capacidad Min.", value: "CfvCapacityMin" },
					{ text: "Capacidad MaX", value: "CfvCapacity" },
					{ text: "Artículo", value: "ArcDescription" },
				],
			},
		}),
		methods: {
			save(item) {
				console.log("Guardar", item);
				if(item.CfvCapacity <= 0){
					this.$fun.alert("Ingrese Capacidad Maxima", "warning")
					return
				}

				if(item.CfvCapacityMin <= 0){
					this.$fun.alert("Ingrese Capacidad Minima", "warning")
					return
				}

				if(item.CfvCapacityMin > item.CfvCapacity){
					this.$fun.alert("Error, Capacidad minima no puede superior que capacidad maxima", "warning")
					return
				}

					
				item.SecStatus = 1;
				item.usrCreateID = this.$fun.getUserID();
				item.save();
			},
		},
		created() {
			this.filter = {};

			this.configVehicle = {
				url: _sVehicleService.GetVehicleList(),
				title: this.label,
				params: { search: "", requestID: this.$fun.getUserID(), AdvID: 0 },
			};
		},
	};
</script>
