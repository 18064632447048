import Service from "../Service";
import Vue from "vue";
const resource = "ConfigurationVehicle/";


export default {
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
    save(prd, requestID) {
        return Service.post(resource + "save", prd, {
            params: { requestID: requestID },
        });
    },

    GetVehicleList() {
        return Vue.prototype.$http.defaults.baseURL + "/ConfigurationVehicle/GetVehicleList";
    },
}